import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { adminLogin } from '../api/api';

import "../styles/admin.css"

function AdminSignIn() {
 const [email, setEmail] = useState('');
 const [password, setPassword] = useState('');
 const navigate = useNavigate();

 const location = useLocation();

 useEffect(() => {
   const routeClass = location.pathname.replace(/\//g, '');
   
   document.body.classList.add(routeClass);

   return () => {
     document.body.classList.remove(routeClass);
   };
 }, [location.pathname]);

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await adminLogin(email, password)
      
      if (response.data) {
        localStorage.setItem('adminToken', response.data.access_token);
        navigate('/admin-dashboard');
      } else {
        alert('Invalid credentials');
      }
    } catch (error) {
      console.error('Admin login error:', error);
      alert('Login failed.');
    }
  };

  return (
    <div className="cabana--club__sso cabana--club__sso-admin">
      <div className='cabana--sso-wrapper'>
        <div className='cabana--sso-wrapper_form-container admin-login'>
            <img src="/images/cc-logo-wt.png" alt="Cabana Logo" className="cabana--logo" />
            <h2 class="admin-heading">Login to Cabana Dashboard</h2>
            <form onSubmit={handleLogin}>
                <input
                type="email"
                placeholder="Admin Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                />
                <input
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                />
                <button type="submit">Login</button>
            </form>
    </div>
    </div>
    </div>
  );
}

export default AdminSignIn;
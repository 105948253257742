import React, { useEffect, useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';


const Header = ({ email }) => {
    const navigate = useNavigate();

    const handleLogout = () => {
        localStorage.removeItem('adminToken');
        navigate('/admin-signin');
      };    

  return (
    <nav className="navbar default-layout col-lg-12 col-12 p-0 fixed-top d-flex align-items-top flex-row">
    <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-start">
      <div className="me-3">
        <button className="navbar-toggler navbar-toggler align-self-center" type="button" data-bs-toggle="minimize">
          <span className="icon-menu"></span>
        </button>
      </div>
      <div>
        <Link to="/admin-dashboard" className="navbar-brand brand-logo">
        <img src="/images/CC-logo.png" alt="Cabana Logo" className="cabana--logo" />
        </Link>
     </div>
    </div>
    <div className="navbar-menu-wrapper d-flex align-items-top">
      <ul className="navbar-nav">
        <li className="nav-item fw-semibold d-none d-lg-block ms-0">
          <h1 className="welcome-text">Welcome, <span className="text-black fw-bold">Admin</span></h1>
          <h3 className="welcome-sub-text">{email} </h3>
        </li>
      </ul>
      <ul className="navbar-nav ms-auto">
        <li className="nav-item dropdown d-none d-lg-block user-dropdown">
         <button className='logout' onClick={handleLogout}>Logout</button>
        </li>
      </ul>
      <button className="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" data-bs-toggle="offcanvas">
        <span className="mdi mdi-menu"></span>
      </button>
    </div>
  </nav>
  );
};
export default Header;
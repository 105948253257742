import React, { useState, useEffect } from 'react';
import { useSearchParams, Link, useNavigate } from 'react-router-dom';
import { resetPasswordHandler } from '../api/api';
import '../styles/sso.css';

export default function ForgotPassword() {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [token, setToken] = useState(null);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  let [searchParams] = useSearchParams();

  async function handleSubmit(e) {
    e.preventDefault();
    if (!password) {
      setError('Please enter your password');
      return;
    }

    if (password && password !== confirmPassword) {
      setError('Password do not match');
      return;
    }

    try {
      setLoading(true);
      const result = await resetPasswordHandler({ password, token });
      console.log('resetting password', result);
      window.location.href = `/?client=${result.client}`;
    } catch (error) {
      console.log(error);
      setError('Server Error try again later');
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (searchParams.get('token')) {
      setToken(searchParams.get('token'));
    }
  }, []);

  return (
    <div className="cabana--club__sso">
      {token ? (
        <div className="cabana--sso-wrapper">
          <div className="cabana--sso-wrapper_form-container">
            <img
              src="/images/cc-logo-wt.png"
              alt="Cabana Logo"
              className="cabana--logo"
            />
            <h2>Reset Your Password</h2>
            <p>Enter your new password</p>
            {error && <p className="error">{error}</p>}
            <form onSubmit={handleSubmit}>
              <input
                type="password"
                id="password"
                placeholder="Enter Your Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />

              <input
                type="password"
                id="confirmPassword"
                placeholder="Enter Your Confirmation Password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />

              <button type="submit" disabled={loading}>
                Send
              </button>
            </form>
          </div>
        </div>
      ) : (
        <div className="cabana--club__unauthorise-request">
          <h1>Unauthorized Request</h1>
          <p>Your request cannot be authorized. Check your credentials.</p>
          <Link to="/signup">Register/Login</Link>
        </div>
      )}
    </div>
  );
}

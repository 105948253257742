import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import SSO from './components/SingleSignOn';
import SSOUP from './components/SingleSignUp';
import AdminSignIn from './components/AdminSignIn';
import Dashboard from './components/Dashboard';
import Members from './components/Members';
import Stores from './components/stores/Stores';
import ForgotPassword from './components/ForgotPassword';
import ResetPassword from './components/ResetPassword';

function App() {

  return (
    <Router>
      <Routes>
        <Route path="/" element={<SSO />} />
        <Route path="/signup" element={<SSOUP />} />
        <Route path="/admin-signin" element={<AdminSignIn />} />
        <Route path="/admin-dashboard" element={<Dashboard />} />
        <Route path="/admin-members" element={<Members />} />
        <Route path="/stores" element={<Stores />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/password/reset" element={<ResetPassword />} />
      </Routes>
    </Router>
  );
}

export default App;

// import React, { useEffect, useState } from 'react';
// import axios from 'axios';
// import SSO from './components/SingleSignOn';
// import SSOUP from './components/SingleSignUp'

// import './App.css';
// import { useLocation } from 'react-router-dom';

// function App() {
//   const [view, setView] = useState('SSO');
//   const [email, setEmail] = useState('');

//   const location = useLocation();
//   const [store, setStore] = useState('');
//   const [platform, setPlatform] = useState('');
//   // const [membership, setMembership] = useState('');

//   useEffect(() => {
//     const queryParams = new URLSearchParams(location.search);
//       setStore(queryParams.get('store'));
//       setPlatform(queryParams.get('platform'));
//       // setPlatform(queryParams.get('membership'));
//   }, [location.search]);

//   const [config, setConfig] = useState(null);

//   useEffect(() => {
//     fetch('https://sso.universal-apps.com/api/config')
//       .then((response) => response.json())
//       .then((data) => setConfig(data))
//       .catch((error) => console.error('Error fetching config:', error));
//   }, []);

//   const checkEmailExists = async (email) => {
//     try {
//       const response = await fetch(`${config.REACT_APP_API_URL}/api/auth/emailexists/${email}`);

//       if (!response.ok) {
//         throw new Error('Network response was not ok');
//       }
//       console.log(response.body);
//       const data = await response.json();
//       return data.exists // Assume your API returns { exists: true/false }
//     } catch (error) {
//       console.error('Error fetching email existence:', error);
//       throw error; // Rethrow the error for handling in the login form
//     }
//   };

//   const handleLoginSubmit = async (email) => {
//     const emailExists = await checkEmailExists(email);
//     if (!emailExists) {
//       // setEmail(email);
//       setView('SSOUP');
//     } else {
//       console.log('Email found, proceed with login...');
//       try {
//         const response = await axios.post(`${config.REACT_APP_API_URL}/api/auth/login`, {
//           email
//         });

//         if (response.data) {
//           let redirectUrl = `https://dhc-development.myshopify.com/account/login/multipass/${response.data}`
//           window.location.href = redirectUrl;
//           //console.log(response.data);
//         }
//       } catch (error) {
//         // setError('Invalid username or password');
//       }
//       // Here you can handle the login logic, e.g., redirect to a dashboard or call a login API
//     }
//   };

//   const handleSignupSubmit = async ({
//     first_name,
//     last_name,
//     email,
//     phone,
//     password,
//     role,
//     platform })  => {
//     try {
//       const response = await axios.post(`${config.REACT_APP_API_URL}/api/auth/canna-cabana-dev/register`, {
//         first_name,
//         last_name,
//         email,
//         phone,
//         password,
//         role,
//         platform
//       });
//       console.log(response)
//       if (response.data) {
//         //console.log(response.data);
//         let redirectUrl = `https://dhc-development.myshopify.com/account/login/multipass/${response.data}`
//         window.location.href = redirectUrl;
//       }
//     } catch (error) {
//       // setError('Something goes wrong!');
//     }
//   };

//   return (
//     <div className="cabana--club__sso">
//       {config ? (
//         view === 'SSO' ? (
//           <SSO platform={platform} store={store} onSubmit={handleLoginSubmit} />
//         ) : (
//           <SSOUP signinemail={email} platform={platform} store={store} onSubmit={handleSignupSubmit} />
//         )
//       ) : (
//         <p>Loading...</p>
//       )}
//     </div>
//   );
// }

// export default App;

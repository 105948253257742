import React, { useState, useContext } from 'react';
import { ConfigContext } from '../contexts/ConfigContext';
import { useNavigate } from 'react-router-dom';
import { checkEmailExists, loginUser, generateOtp } from '../api/api';
import '../styles/sso.css';

const SingleSignOn = () => {
  const { client } = useContext(ConfigContext);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loginType, setLoginType] = useState('password');
  const [otp, setOtp] = useState('');
  const [isOtpSend, setIsOtpSend] = useState(false);
  const [loading, setLoading] = useState(false);

  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const navigate = useNavigate();

  // todo: Update requestObject when email or client changes

  function clearErrorSuccess() {
    setError('');
    setSuccess('');
  }
  const handleLoginSubmit = async (e) => {
    e.preventDefault();
    clearErrorSuccess();
    if (!email) {
      setError('Email is missing');
      return;
    }

    if (loginType === 'password' && !password) {
      setError('Password is missing');
      return;
    }

    setLoading(true);
    const emailExists = await checkEmailExists(email);
    if (!emailExists) {
      navigate('/signup', { state: { email, loginType }, client: client });
    } else {
      const { success, data, error } = await loginUser({
        client,
        password,
        email,
        login_type: loginType,
        otp,
      });

      if (success) {
        const token = data.token;
        console.log('Token received:', token);
        console.log('Redirecting to:', data.redirect_uri);
        // Uncomment below to enable redirection
        window.location.href = data.redirect_uri;
      } else {
        console.log(error);
        setError(error);
      }
    }

    setLoading(false);
  };

  const handleOtp = async (e) => {
    e.preventDefault();
    clearErrorSuccess();
    if (!email) {
      setError('Please enter the email');
      return;
    }

    setLoading(true);
    const { success, error, data } = await generateOtp(email);

    if (success) {
      setSuccess(data.message);
      setIsOtpSend(true);
    } else {
      setError(error);
    }

    setLoading(false);
  };

  return (
    <div className="cabana--club__sso">
      {client ? (
        <div className="cabana--sso-wrapper">
          <div className="cabana--sso-wrapper_form-container">
            <img
              src="images/cc-logo-wt.png"
              alt="Cabana Logo"
              className="cabana--logo"
            />
            <h2>Join for Instant Savings</h2>
            <p>
              Unlock <strong>UNBEATABLE Member Prices</strong> for Free!
            </p>
            {error && <p className="error">{error}</p>}
            {success && <p className="success">{success}</p>}

            <form onSubmit={handleLoginSubmit}>
              <input
                type="text"
                id="email"
                placeholder="Enter Your Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />

              {loginType && loginType === 'password' ? (
                <input
                  type="password"
                  id="password"
                  placeholder="Enter Your Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              ) : null}

              {loginType && loginType === 'otp' && isOtpSend ? (
                <input
                  type="text"
                  id="otp"
                  placeholder="Enter your otp"
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                />
              ) : null}

              {loginType && loginType === 'otp' && !isOtpSend ? (
                <button onClick={handleOtp} disabled={loading}>
                  Send OTP
                </button>
              ) : (
                <button type="submit" disabled={loading}>
                  {loginType && loginType === 'otp'
                    ? 'Verify Otp and Join Cabana Club'
                    : 'Join Cabana Club for Free'}
                </button>
              )}
            </form>

            {loginType && (
              <p
                style={{
                  cursor: 'pointer',
                  textDecoration: 'underline',
                  margin: 0,
                }}
                onClick={() =>
                  setLoginType(loginType === 'password' ? 'otp' : 'password')
                }
              >
                Login with {loginType === 'password' ? 'OTP' : 'Password'}
              </p>
            )}

            <p
              style={{ cursor: 'pointer', textDecoration: 'underline' }}
              onClick={() =>
                navigate('/forgot-password', {
                  client: client,
                  state: { email },
                })
              }
            >
              Forgot password?
            </p>
          </div>
        </div>
      ) : (
        <div className="cabana--club__unauthorise-request">
          <h1>Unauthorized Request</h1>
          <p>Your request cannot be authorized. Check your credentials.</p>
        </div>
      )}
    </div>
  );
};

export default SingleSignOn;

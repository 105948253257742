import React, { useEffect, useContext, useState } from 'react';
import { ConfigContext } from '../contexts/ConfigContext';
import { useNavigate } from 'react-router-dom';
import { fetchUsers } from '../api/api';
import { jwtDecode } from 'jwt-decode';
// import "../assets/vendors/feather/feather.css";
// // import "../assets/vendors/mdi/css/materialdesignicons.min.css";
// import "../assets/vendors/ti-icons/css/themify-icons.css";
// import "../assets/vendors/font-awesome/css/font-awesome.min.css";
// import "../assets/vendors/typicons/typicons.css";
// import "../assets/vendors/simple-line-icons/css/simple-line-icons.css";
// import "../assets/vendors/css/vendor.bundle.base.css";
// import "../assets/vendors/bootstrap-datepicker/bootstrap-datepicker.min.css";
// import "../assets/vendors/datatables.net-bs4/dataTables.bootstrap4.css";
// import "../assets/js/select.dataTables.min.css";
import "../assets/css/style.css";
import Header from './shared/header';
import Navigation from './shared/navigation';
import { Link } from 'react-router-dom';


function Dashboard() {
  const { config } = useContext(ConfigContext);
  const [users, setUsers] = useState([]);
  const [adminEmail, setAdminEmail] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('adminToken');
    if (!token) {
      navigate('/admin-signin');
      return;
    }

    const decodedToken = jwtDecode(token);
    setAdminEmail(decodedToken.email);

    const fetchCustomers = async () => {
      try {
        const customers = await fetchUsers(token);
        setUsers(customers);
      } catch (error) {
        console.error('Failed to fetch users:', error);
      }
    };
    
    fetchCustomers();
  }, [config, navigate]);
  
  // const handleLogout = () => {
  //   localStorage.removeItem('adminToken');
  //   navigate('/admin-signin');
  // };
  const maskEmail = (email) => {
    const [localPart, domain] = email.split('@');
    const maskedLocalPart = localPart.slice(0, 2) + '...' + localPart.slice(-1);
    return `${maskedLocalPart}@${domain}`;
  };

  return (
    
    <div className="dashboard">
        <div className="container-scroller">
     
      <Header email={adminEmail}></Header>

      <div className="container-fluid page-body-wrapper">
       <Navigation></Navigation>
        <div className="main-panel">
          <div className="content-wrapper">
            <div className="row">
              <div className="col-sm-12">
                <div className="home-tab">
                  <div className="d-sm-flex align-items-center justify-content-between border-bottom">
                    <ul className="nav nav-tabs" role="tablist">
                      <li className="nav-item">
                      <Link to="/admin-dashboard" className="nav-link active" id="profile-tab" data-bs-toggle="tab" href="#audiences" role="tab" aria-selected="false">
                          Overview
                      </Link>
                      </li>
                      <li className="nav-item">
                      <Link to="/admin-members" className="nav-link" id="profile-tab" data-bs-toggle="tab" href="#audiences" role="tab" aria-selected="false">
                          Members
                      </Link>
                      </li>
                    </ul>
                    <div>
                      <div className="btn-wrapper">
                        <a href="#" className="btn btn-otline-dark align-items-center"><i className="icon-share"></i> Share</a>
                        <a href="#" className="btn btn-otline-dark"><i className="icon-printer"></i> Print</a>
                        <a href="#" className="btn btn-primary text-white me-0"><i className="icon-download"></i> Export</a>
                      </div>
                    </div>
                  </div>
                  <div className="tab-content tab-content-basic">
                    <div className="tab-pane fade show active" id="overview" role="tabpanel" aria-labelledby="overview">
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="statistics-details d-flex align-items-center justify-content-between">
                            <div>
                              <p className="statistics-title">New Members</p>
                              <h3 className="rate-percentage">16</h3>
                              <p className="text-danger d-flex"><i className="mdi mdi-menu-down"></i><span>-0.5%</span></p>
                            </div>
                            <div>
                              <p className="statistics-title">Club Members</p>
                              <h3 className="rate-percentage">2,257</h3>
                              <p className="text-success d-flex"><i className="mdi mdi-menu-up"></i><span>+0.1%</span></p>
                            </div>
                            <div>
                              <p className="statistics-title">Elite Members</p>
                              <h3 className="rate-percentage">3</h3>
                              <p className="text-success d-flex"><i className="mdi mdi-menu-down"></i><span>+0.1%</span></p>
                            </div>
                            <div className="d-none d-md-block">
                              <p className="statistics-title">This Month Elite Memberships</p>
                              <h3 className="rate-percentage">3</h3>
                              <p className="text-success d-flex"><i className="mdi mdi-menu-down"></i><span>+0.1%</span></p>
                            </div>
                            <div className="d-none d-md-block">
                              <p className="statistics-title">Signups This Month</p>
                              <h3 className="rate-percentage">68</h3>
                              <p className="text-danger d-flex"><i className="mdi mdi-menu-down"></i><span>68</span></p>
                            </div>
                            <div className="d-none d-md-block">
                              <p className="statistics-title">Signups Last 6 Months</p>
                              <h3 className="rate-percentage">1,002</h3>
                              <p className="text-success d-flex"><i className="mdi mdi-menu-down"></i><span>+0.8%</span></p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-8 d-flex flex-column">
                          <div className="row flex-grow">
                            <div className="col-12 col-lg-4 col-lg-12 grid-margin stretch-card">
                              <div className="card card-rounded">
                                <div className="card-body">
                                  <div className="d-sm-flex justify-content-between align-items-start">
                                    <div>
                                      <h4 className="card-title card-title-dash">Performance Line Chart</h4>
                                      <h5 className="card-subtitle card-subtitle-dash">Lorem Ipsum is simply dummy text of the printing</h5>
                                    </div>
                                    <div id="performanceLine-legend"></div>
                                  </div>
                                  <div className="chartjs-wrapper mt-4">
                                    <canvas id="performanceLine" width=""></canvas>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 d-flex flex-column">
                          <div className="row flex-grow">
                            <div className="col-md-6 col-lg-12 grid-margin stretch-card">
                              <div className="card bg-primary card-rounded">
                                <div className="card-body pb-0">
                                  <h4 className="card-title card-title-dash text-white mb-4">Cabbana Elite Members</h4>
                                  <div className="row">
                                    <div className="col-sm-4">
                                      <p className="status-summary-ight-white mb-1">All Time</p>
                                      <h2 className="text-info">3</h2>
                                    </div>
                                    <div className="col-sm-8">
                                      <div className="status-summary-chart-wrapper pb-4">
                                        <canvas id="status-summary"></canvas>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-8 d-flex flex-column">
                          <div className="row flex-grow">
                            <div className="col-12 grid-margin stretch-card">
                              <div className="card card-rounded table-darkBGImg">
                                <div className="card-body">
                                  <div className="col-sm-8">
                                    <h3 className="text-white upgrade-info mb-0"> Enhance your <span className="fw-bold">Campaign</span> for better outreach </h3>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row flex-grow">
                            <div className="col-12 grid-margin stretch-card">
                              <div className="card card-rounded">
                                <div className="card-body">
                                  <div className="d-sm-flex justify-content-between align-items-start">
                                    <div>
                                      <h4 className="card-title card-title-dash">Elite Members</h4>
                                      <p className="card-subtitle card-subtitle-dash">You have 3 elite members</p>
                                    </div>
                                  </div>
                                  <div className="table-responsive  mt-1">
                                  <table className="table select-table">
                                        <thead>
                                          <tr>
                                            <th>
                                              <div className="form-check form-check-flat mt-0">
                                                <label className="form-check-label">
                                                  <input type="checkbox" className="form-check-input" aria-checked="false" id="check-all" /><i className="input-helper"></i></label>
                                              </div>
                                            </th>
                                            <th>Customer</th>
                                            <th>MemberShip</th>
                                            <th>Register Platform</th>
                                            <th>Status</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {users.map(user => (
                                            user.role === 'elite_member' ? (
                                            <tr key={user.id}>
                                              <td>
                                                <div className="form-check form-check-flat mt-0">
                                                  <label className="form-check-label">
                                                    <input type="checkbox" className="form-check-input" aria-checked="false" /><i className="input-helper"></i></label>
                                                </div>
                                              </td>
                                              <td>
                                                <div className="d-flex ">
                                                  <div>
                                                    <h6>{user.first_name} {user.last_name}</h6>
                                                    <p>{maskEmail(user.email)}</p>
                                                  </div>
                                                </div>
                                              </td>
                                              <td>
                                                <h6>Members Type</h6>
                                                <p>
                                                  Elite
                                                </p>
                                              </td>
                                              <td>
                                                <div>
                                                  <div className="d-flex justify-content-between align-items-center mb-1 max-width-progress-wrap">
                                                    <p className="text-success">Shopify</p>
                                                    <p>Since: {user.created_at}</p>
                                                  </div>
                                                </div>
                                              </td>
                                              <td>
                                                <div className="badge badge-opacity-success">Active</div>
                                              </td>
                                            </tr>
                                            ) 
                                            : 
                                            null
                                          ))}
                                        </tbody>
                                      </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <footer className="footer">
            <div className="d-sm-flex justify-content-center justify-content-sm-between">
              <span className="float-none float-sm-end d-block mt-1 mt-sm-0 text-center">Copyright © 2024. All rights reserved.</span>
            </div>
          </footer>
         </div>
      </div>
    </div>
        {/* <h2>Welcome, {adminEmail}</h2>
        <button onClick={handleLogout}>Logout</button>
      <h2>User List</h2> */}
      {/* <ul>
        {users.map(user => (
          <li key={user.id}>{user.email}</li>
        ))}
      </ul> */}
    </div>
  );
}

export default Dashboard;

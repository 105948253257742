import React, { useState, useEffect, useContext } from 'react';
import { ConfigContext } from '../contexts/ConfigContext';
import { useLocation } from 'react-router-dom';
import { registerUser } from '../api/api';
import '../styles/sso.css';

const SingleSignUp = () => {
  const { client } = useContext(ConfigContext);

  const location = useLocation();
  const initialEmail = location.state?.email || '';

  const [userData, setUserData] = useState({
    first_name: '',
    last_name: '',
    email: initialEmail,
    phone: '',
    client: client || '',
    password: '',
    confirm_password: '',
    date_of_birth: '',
  });

  useEffect(() => {
    if (client) {
      setUserData((prevData) => ({
        ...prevData,
        client: client,
      }));
    }
  }, [client]);

  const [error, setError] = useState('');
  // todo:: needs to add the proper validation
  const handleSignupSubmit = async (e) => {
    e.preventDefault();
    if (userData.password && userData.password !== userData.confirm_password) {
      setError('Your password do not match');
      return;
    }
    try {
      const data = await registerUser({ ...userData });
      if (data) {
        // const token = data.token;
        // console.log("Token received:", token);
        // console.log("Redirecting to:", data.redirect_uri);
        // Uncomment below to enable redirection
        window.location.href = data.redirect_uri;
      }
    } catch (error) {
      setError('An error occurred during signup.');
      console.log(error);
    }
  };

  return (
    <div className="cabana--club__sso">
      <div className='cabana--sso-wrapper'>
        <div className='cabana--sso-wrapper_form-container'>
          <img src="/images/cc-logo-wt.png" alt="Cabana Logo" className="cabana--logo" />
          <h2>Join for Instant Savings</h2>
          <p>
            Unlock <strong>UNBEATABLE Member Prices</strong> for Free!
          </p>
          {error && <p className="error">{error}</p>}
          <form onSubmit={handleSignupSubmit}>
            <input type="hidden" name="role" value="" />
            <input type="hidden" name="password" value="" />

            <input
              type="text"
              id="first_name"
              placeholder="First Name"
              value={userData.first_name}
              onChange={(e) =>
                setUserData({ ...userData, first_name: e.target.value })
              }
              required
            />
            <input
              type="text"
              id="last_name"
              placeholder="Last Name"
              value={userData.last_name}
              onChange={(e) =>
                setUserData({ ...userData, last_name: e.target.value })
              }
              required
            />
            <input
              type="text"
              id="email"
              placeholder="Enter Your Email"
              value={userData.email}
              onChange={(e) =>
                setUserData({ ...userData, email: e.target.value })
              }
              required
            />
            <input
              type="text"
              id="phone"
              placeholder="Phone Number"
              value={userData.phone}
              onChange={(e) =>
                setUserData({ ...userData, phone: e.target.value })
              }
              required
            />

            <input
              type="password"
              id="password"
              placeholder="Enter Password"
              value={userData.password}
              onChange={(e) =>
                setUserData({ ...userData, password: e.target.value })
              }
              required
            />

            <input
              type="password"
              id="confirm_password"
              placeholder="Enter Confirm Password"
              value={userData.confirm_password}
              onChange={(e) =>
                setUserData({ ...userData, confirm_password: e.target.value })
              }
              required
            />

            <input
              type="date"
              id="date_of_birth"
              placeholder="Enter Your Birthday"
              value={userData.date_of_birth}
              onChange={(e) =>
                setUserData({ ...userData, date_of_birth: e.target.value })
              }
              required
            />

            <button type="submit">Join Cabana Club for Free</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SingleSignUp;

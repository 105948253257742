// src/api/api.js
import axios from 'axios';

const baseURL = process.env.REACT_APP_API_URL;
const API_URL = `${baseURL}/api`;

const service = axios.create({
  baseURL: API_URL,
});

export const fetchConfig = async () => {
  try {
    const response = await service.get(`${API_URL}/config`);
    return response.data;
  } catch (error) {
    console.error('Error fetching config:', error);
    throw error;
  }
};

// Check if email exists
export const checkEmailExists = async (email) => {
  try {
    const response = await service.get(`${API_URL}/auth/emailexists/${email}`);
    return response.data.exists;
  } catch (error) {
    console.error('Error fetching email existence:', error);
    throw error;
  }
};

export const forgotPasswordHandler = async ({ email, client }) => {
  try {
    const resp = await service.post(`${API_URL}/auth/forgot-password`, {
      email,
      client,
    });
    return resp.data;
  } catch (error) {
    console.error('Error forgot password:', error);
    throw error;
  }
};

export const resetPasswordHandler = async ({ password, token }) => {
  const resp = await service.post(`${API_URL}/auth/password/reset`, {
    password,
    token,
  });
  return resp.data;
};

// Login API
export const loginUser = async (userData) => {
  try {
    const response = await service.post(`${API_URL}/auth/authenticate`, {
      userData,
    });

    return {
      success: true,
      data: response.data,
    };
  } catch (error) {
    console.log('Error login:', error);
    return {
      success: false,
      error:
        error?.response?.data?.message ??
        'An error occurred during login try again later ',
    };
  }
};

// Register API
export const registerUser = async (userData) => {
  console.log(userData);
  try {
    const response = await service.post(`${API_URL}/auth/register`, userData);
    return response.data;
  } catch (error) {
    console.error('Error registering user:', error);
    throw new Error(error?.response?.data?.message ?? 'Invalid Credentials');
  }
};

// Forgot Password
export const forgotPassword = async (email) => {
  console.log(email);
};

export const adminLogin = async (email, password) => {
  try {
    const response = await service.post(`${API_URL}/auth/admin/login`, {
      email,
      password,
    });
    return response;
  } catch (error) {
    console.error('Error registering user:', error);
    throw error;
  }
};
export const fetchUsers = async (token) => {
  try {
    const response = await service.get(`${API_URL}/users`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    console.error('Error registering user:', error);
    throw error;
  }
};

export const fetchStores = async (token) => {
  try {
    const response = await service.get(`${API_URL}/stores/list`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    console.error('Error registering user:', error);
    throw error;
  }
};

export const generateOtp = async (email) => {
  try {
    const response = await service.post('/otp/generate', { email });
    return {
      success: true,
      data: response.data,
    };
  } catch (error) {
    return {
      success: false,
      error:
        error?.response?.data ??
        'Error while sending OTP please try again later.',
    };
  }
};

import React, { useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';

import { forgotPasswordHandler } from '../api/api';
import '../styles/sso.css';
import { ConfigContext } from '../contexts/ConfigContext';

export default function ForgotPassword() {
  const location = useLocation();
  const { client } = useContext(ConfigContext);

  const initialEmail = location.state?.email || '';

  const [email, setEmail] = useState(initialEmail);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(false);

  async function handleSubmit(e) {
    e.preventDefault();

    try {
      setLoading(true);
      const resp = await forgotPasswordHandler({ email, client });
      const { message } = resp;
      setSuccess(message);
    } catch (error) {
      console.log(error);
      setError('Server Error try again later');
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="cabana--club__sso">
      {client ? (
        <div className="cabana--sso-wrapper">
          <div className="cabana--sso-wrapper_form-container">
            <img
              src="/images/cc-logo-wt.png"
              alt="Cabana Logo"
              className="cabana--logo"
            />
            <h2>Forgot your password</h2>
            <p>Don't worry we'll help you to recover your password</p>
            {error && <p className="error">{error}</p>}
            {success && <p className="success">{success}</p>}
            <form onSubmit={handleSubmit}>
              <input
                type="text"
                id="email"
                placeholder="Enter Your Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />

              <button type="submit" disabled={loading}>
                Send
              </button>
            </form>
          </div>
        </div>
      ) : (
        <div className="cabana--club__unauthorise-request">
          <h1>Unauthorized Request</h1>
          <p>Your request cannot be authorized. Check your credentials.</p>
        </div>
      )}
    </div>
  );
}

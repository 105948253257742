import React, { useEffect, useContext, useState } from 'react';
import { ConfigContext } from '../contexts/ConfigContext';
import { useNavigate } from 'react-router-dom';
import { fetchUsers } from '../api/api';
import { jwtDecode } from 'jwt-decode';
import "../assets/css/style.css";
import Header from './shared/header';
import Navigation from './shared/navigation';
import { Link } from 'react-router-dom';

function Members() {
  const { config } = useContext(ConfigContext);
  const [users, setUsers] = useState([]);
  const [adminEmail, setAdminEmail] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 50;
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('adminToken');
    if (!token) {
      navigate('/admin-signin');
      return;
    }

    const decodedToken = jwtDecode(token);
    setAdminEmail(decodedToken.email);

    const fetchCustomers = async () => {
      try {
        const customers = await fetchUsers(token);
        setUsers(customers);
      } catch (error) {
        console.error('Failed to fetch users:', error);
      }
    };
    
    fetchCustomers();
  }, [config, navigate]);

  const handleLogout = () => {
    localStorage.removeItem('adminToken');
    navigate('/admin-signin');
  };

  const maskEmail = (email) => {
    const [localPart, domain] = email.split('@');
    const maskedLocalPart = localPart.slice(0, 2) + '...' + localPart.slice(-1);
    return `${maskedLocalPart}@${domain}`;
  };

  // Calculate pagination data
  const totalPages = Math.ceil(users.length / itemsPerPage);
  const indexOfLastUser = currentPage * itemsPerPage;
  const indexOfFirstUser = indexOfLastUser - itemsPerPage;
  const currentUsers = users.slice(indexOfFirstUser, indexOfLastUser);

  return (
    <div className="dashboard">
      <div className="container-scroller">
        <Header email={adminEmail}></Header>
        <div className="container-fluid page-body-wrapper">
          <Navigation></Navigation>
          <div className="main-panel">
            <div className="content-wrapper">
              <div className="row">
                <div className="col-sm-12">
                  <div className="home-tab">
                    <div className="d-sm-flex align-items-center justify-content-between border-bottom">
                      <ul className="nav nav-tabs" role="tablist">
                        <li className="nav-item">
                          <Link to="/admin-dashboard" className="nav-link" id="profile-tab" data-bs-toggle="tab" href="#audiences" role="tab" aria-selected="false">
                            Overview
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link to="/admin-members" className="nav-link active" id="profile-tab" data-bs-toggle="tab" href="#audiences" role="tab" aria-selected="false">
                            Members
                          </Link>
                        </li>
                      </ul>
                      <div>
                        <div className="btn-wrapper">
                          <a href="#" className="btn btn-otline-dark align-items-center"><i className="icon-share"></i> Share</a>
                          <a href="#" className="btn btn-otline-dark"><i className="icon-printer"></i> Print</a>
                          <a href="#" className="btn btn-primary text-white me-0"><i className="icon-download"></i> Export</a>
                        </div>
                      </div>
                    </div>
                    <div className="tab-content tab-content-basic">
                      <div className="tab-pane fade show active" id="overview" role="tabpanel" aria-labelledby="overview">
                        <div className="row">
                          <div className="col-lg-8 d-flex flex-column">
                            <div className="row flex-grow">
                              <div className="col-12 grid-margin stretch-card">
                                <div className="card card-rounded">
                                  <div className="card-body">
                                    <div className="d-sm-flex justify-content-between align-items-start">
                                      <div>
                                        <h4 className="card-title card-title-dash">All Members</h4>
                                        <p className="card-subtitle card-subtitle-dash">You have {users.length} Total Members</p>
                                      </div>
                                    </div>
                                    <div className="table-responsive mt-1">
                                      <table className="table select-table">
                                        <thead>
                                          <tr>
                                            <th>
                                              <div className="form-check form-check-flat mt-0">
                                                <label className="form-check-label">
                                                  <input type="checkbox" className="form-check-input" aria-checked="false" id="check-all" /><i className="input-helper"></i></label>
                                              </div>
                                            </th>
                                            <th>Customer</th>
                                            <th>MemberShip</th>
                                            <th>Register Platform</th>
                                            <th>Status</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {currentUsers.map(user => (
                                            <tr key={user.id}>
                                              <td>
                                                <div className="form-check form-check-flat mt-0">
                                                  <label className="form-check-label">
                                                    <input type="checkbox" className="form-check-input" aria-checked="false" /><i className="input-helper"></i></label>
                                                </div>
                                              </td>
                                              <td>
                                                <div className="d-flex ">
                                                  <div>
                                                    <h6>{user.first_name} {user.last_name}</h6>
                                                    <p>{maskEmail(user.email)}</p>
                                                  </div>
                                                </div>
                                              </td>
                                              <td>
                                                <h6>Members Type</h6>
                                                <p>
                                                  {user.role === 'elite_member' ? "Elite" : "Club"}
                                                </p>
                                              </td>
                                              <td>
                                                <div>
                                                  <div className="d-flex justify-content-between align-items-center mb-1 max-width-progress-wrap">
                                                    <p className="text-success">Shopify</p>
                                                    <p>Since: {user.created_at}</p>
                                                  </div>
                                                </div>
                                              </td>
                                              <td>
                                                <div className="badge badge-opacity-success">Active</div>
                                              </td>
                                            </tr>
                                          ))}
                                        </tbody>
                                      </table>
                                    </div>
                                    {/* Pagination Controls */}
                                    <div className="pagination">
                                      {Array.from({ length: totalPages }, (_, index) => (
                                        <button
                                          key={index + 1}
                                          onClick={() => setCurrentPage(index + 1)}
                                          disabled={currentPage === index + 1}
                                          className={`page-button ${currentPage === index + 1 ? 'active' : ''}`}
                                        >
                                          {index + 1}
                                        </button>
                                      ))}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <footer className="footer">
              <div className="d-sm-flex justify-content-center justify-content-sm-between">
                <span className="float-none float-sm-end d-block mt-1 mt-sm-0 text-center">Copyright © 2024. All rights reserved.</span>
              </div>
            </footer>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Members;

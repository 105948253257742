// src/contexts/ConfigContext.js
import React, { createContext, useEffect, useState } from 'react';

export const ConfigContext = createContext();

export const ConfigProvider = ({ children }) => {
  const [client, setClient] = useState('');
  const [store, setStore] = useState('');
  const [platform, setPlatform] = useState('');
  const [redirect_uri, setRedirectUri] = useState('');
  const [config, setConfig] = useState('');

  const baseURL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    fetch(`${baseURL}/config`)
      .then((response) => response.json())
      .then((data) => setConfig(data))
      .catch((error) => console.error('Error fetching config:', error));
  }, []);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    // setStore(queryParams.get('store'));
    // setPlatform(queryParams.get('platform'));
    // setRedirectUri(queryParams.get('redirect_uri'));
    setClient(queryParams.get('client'));
  }, []);

  return (
    <ConfigContext.Provider value={{ client }}>
      {children}
    </ConfigContext.Provider>
  );
};
